/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
}

input[type="text"] {
  -webkit-appearance: none;
  outline: none;
}

input[type="email"] {
  -webkit-appearance: none;
  outline: none;
}

input[type="password"] {
  -webkit-appearance: none;
  outline: none;
}

p {
  overflow-wrap: break-word;
}

input[type="number"] {
  -webkit-appearance: none;
  outline: none;
  -moz-appearance: textfield !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.plancardInline {
  box-shadow: 0px 1px 21px #ddd;
  margin-bottom: 20px;
}

.bottomCardtext {
  text-align: left;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.pbcustom {
  padding-bottom: 4px;
}


.bi.bi-check-all.text-teal-400 path {
  fill: green;
}

.svgIcon {
  margin-right: 5px;
}

.plancardInline .block.bg-blue-600.text-white.px-4.py-2.mb-4.rounded.shadow.focus\:border-transparent.focus\:outline-none.mr-4 {
  font-size: 14px;
  height: 40px;
  width: 92%;

}

.plancardInline .block.bg-gray-400.text-white.px-4.py-2.mb-4.rounded.shadow.focus\:border-transparent.focus\:outline-none.mr-2 {
  font-size: 14px;
  height: 40px;
  width: 92%;
}

button.block.bg-blue-600.text-white.px-4.py-2.mb-4.rounded.shadow.focus\:border-transparent.focus\:outline-none.mr-2 {
  font-size: 14px;
  height: 40px;
  width: 92%;
}

.subs-svg {
  height: 17px !important;
  margin-top: -4px;
}

.SelectField {
  width: 100%;
  height: 40px;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 5px;
  background: #eee;
  font-size: 14px;
  text-transform: capitalize;
}

.ListInline {
  display: flex;
}

.ListInline li {
  display: flex;
  margin-right: 1rem;
}

.viewSubscription {
  height: 40px;
  display: inline-block;
  width: 85%;
  line-height: 36px;
  text-align: center;
  padding: 0 !important;
  font-size: 14px;
  float: left;
}

input[type="date"] {
  -webkit-appearance: none;
  outline: none;
}

.react-date-picker__wrapper {
  border: none !important;
}

input {
  background: white;
}

select {
  background: white;
}

tbody tr:nth-of-type(2n + 1) {
  background: #eff6ff;
}

tbody tr:nth-of-type(2n) {
  background: #bfdbfe;
}

.sidebar-nav-list a {
  color: #d1d5db;
}

.sidebar-nav-list a.active {
  color: #fff;
  font-weight: bold;
}

.h-full-custom {
  height: 100%;
}

.mw-75 {
  max-width: 75%;
  width: 100%;
}

.popup_body>.custom-fix {
  position: fixed;
  max-width: 59%;
  background-color: #fff;
  z-index: 999;
  margin: 0;
  padding: 20px;
  width: 100%;
  bottom: 0;
}

.toggle input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 90px;
  height: 45px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.toggle label:after {
  content: "";
  position: absolute;
  top: 7px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.toggle input:checked+label {
  background: #2563eb;
}

.toggle input:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.toggle label:active:after {
  width: 130px;
}

.icon_btn button {
  width: 40px;
  height: 40px;
  z-index: 9;
  position: relative;
  background: none;
  box-shadow: none;
}

.icon_btn i {
  position: absolute;
  top: 11px;
  left: 11px;
  color: #2563eb;
  font-size: 20px;
}

.add_btn.icon_btn button {
  background: #2563eb;
  color: #fff;
  z-index: 0;
}

.add_btn.icon_btn i {
  color: #fff;
}

.add_btn i+button {
  position: absolute;
  background: none !important;
  top: 0;
}

.select_input input {
  max-width: 70px;
}

/* 28-8-20 */

.subs .w-full>p:first-child {
  text-align: left;
  margin-left: 15px;
  font-size: 22px;
}

.subs .w-full p.font-medium {
  text-align: left;
  padding-bottom: 8px;
  margin-left: 15px;
}

.subs .w-full .flex {
  text-align: right;
}




/* Card css */
.card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}

.pay-button:disabled:hover {
  box-shadow: none;
}

.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.mobWidth {
  width: 33%;
}

.SelectFieldMob {
  width: 61%;
}

.MuiDialog-root .MuiCircularProgress-root {
  position: fixed;
  top: 50%;
  left: 50%;
}

.footer-terms-of-service {
  color: rgba(37, 99, 235, var(--tw-bg-opacity));
  font-weight: bold;
  font-size: larger;
}

@media only screen and (min-width: 768px) and (max-width:1200px) {
  .SelectField {

    width: 100% !important;
  }

  .w-1\/2.mobWidth {
    width: 30%;
  }

  .w-1\/2.SelectFieldMob {
    width: 60% !important;
  }

}

@media only screen and (max-width: 767px) {
  .w-9\/12.md\:w-2\/5.lg\:w-1\/2.flex.items-center.mt-8.mb-2 {
    width: 100%;
  }

  .mobWidth {
    width: 35%;
  }

  .SelectFieldMob {
    width: 65%;
  }

  .plancardInline.rounded-md.p-6.flex .w-1\/4.subscriptionBox {
    width: 100%;
  }

  .bottomCardtext {
    padding: 5px 10px;
  }

  .mobHidefield {
    display: none;
  }

  .block.bg-gray-400.text-white.px-4.py-2.mb-4.rounded.shadow.focus\:border-transparent.focus\:outline-none.mr-2 {
    font-size: 14px;
    height: 40px;
    width: 100%;
    margin-bottom: 5px;
    padding: 0;
  }

  .plancardInline.rounded-md.p-6.flex .w-full {
    margin-left: -15px;
  }

  .ListInline li {
    margin-right: 0.2rem;
    font-size: 13px;
    width: 47%;
  }

  .ListInline {
    display: flex;
    flex-wrap: wrap;
  }

  .bi.bi-check-all.text-teal-400 {
    height: 20px;
  }

  .subs .w-full>p:first-child {
    font-size: 19px;
  }

  .svgIcon {
    margin-right: 0px;
  }

  .flex.mobWrap {
    flex-wrap: wrap;
  }

  .SelectFieldMobfull {
    width: 100%;
    margin-left: 15px;
  }

  .viewSubscription {
    width: 100%;
  }

  .plancardInline {
    flex-wrap: wrap;
    padding: 10px 15px;
  }

  .instruction-model {
    width: 74%;
  }
}